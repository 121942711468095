import { User } from 'src/API';

/**
 * ユーザー名の表示
 * - フルネームを表示
 *
 * @param {User} user - userのデータ
 *
 * @returns {string}
 */
// TODO 削除 coteam4/src/feature/user/userUtils.tsに移動
export const showUserFullName = (user?: User | User | null | undefined | any): string => {
  if (!user) {
    return '';
  }
  const fullName = `${user.lastName} ${user.firstName}`;

  return fullName;
};
