import { useQuery, gql, useMutation } from '@apollo/client';
import { getMemberManager, listMemberManagerByAdminGroup } from 'src/graphql/queries';
import {
  ListMemberManagerByAdminGroupQuery,
  ListMemberManagerByAdminGroupQueryVariables,
  GetMemberManagerQuery,
  GetMemberManagerQueryVariables,
  MemberManager,
  CreateMemberManagerMutation,
  CreateMemberManagerMutationVariables,
  UpdateMemberManagerMutation,
  DeleteMemberManagerMutation,
  DeleteMemberManagerMutationVariables,
  UpdateMemberManagerMutationVariables,
} from 'src/API';
import { useCurrentUserQuery } from '../user';
import {
  createMemberManager,
  deleteMemberManager,
  updateMemberManager,
} from 'src/graphql/mutations';

// ----------------------------------------------------------------------

export const useMemberManagersQuery = () => {
  const { currentUser } = useCurrentUserQuery();
  const memberManagerRes = useQuery<
    ListMemberManagerByAdminGroupQuery,
    ListMemberManagerByAdminGroupQueryVariables
  >(gql(listMemberManagerByAdminGroup), {
    variables: { adminGroup: currentUser?.adminGroup ?? '' },
    skip: !currentUser?.adminGroup,
    notifyOnNetworkStatusChange: true,
  });
  const memberManagers =
    memberManagerRes.data?.listMemberManagerByAdminGroup?.items.filter((item) => item !== null) ??
    [];

  return { memberManagers, ...memberManagerRes };
};

export const useMemberManagerQuery = (memberManagerId?: string | null) => {
  const memberManagerRes = useQuery<GetMemberManagerQuery, GetMemberManagerQueryVariables>(
    gql(getMemberManager),
    {
      variables: { id: memberManagerId ?? '' },
      skip: !memberManagerId,
      notifyOnNetworkStatusChange: true,
    }
  );

  const memberManager = memberManagerRes.data?.getMemberManager as MemberManager;

  return { memberManager, ...memberManagerRes };
};

// ----------------------------------------------------------------------

export const useMemberManagerUpdateMutation = (refetch?: { refetchQueries: any[] }) => {
  const userMutation = useMutation<
    UpdateMemberManagerMutation,
    UpdateMemberManagerMutationVariables
  >(gql(updateMemberManager), refetch);
  return userMutation;
};

export const useMemberMangerCreateMutation = (refetch?: { refetchQueries: any[] }) => {
  const userMutation = useMutation<
    CreateMemberManagerMutation,
    CreateMemberManagerMutationVariables
  >(gql(createMemberManager), refetch);
  return userMutation;
};

export const useMemberManagerDeleteMutation = (refetch?: { refetchQueries: any[] }) => {
  const userMutation = useMutation<
    DeleteMemberManagerMutation,
    DeleteMemberManagerMutationVariables
  >(gql(deleteMemberManager), refetch);
  return userMutation;
};

// ----------------------------------------------------------------------
