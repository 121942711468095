import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { awsconfig } from 'src/config';
import { Auth } from 'aws-amplify';

const config = {
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
};
const httpLink = createHttpLink({ uri: awsconfig.aws_appsync_graphqlEndpoint });

export const client = new ApolloClient({
  link: ApolloLink.from([
    // @ts-ignore
    createAuthLink(config),
    // @ts-ignore
    createSubscriptionHandshakeLink(config, httpLink),
  ]),
  cache: new InMemoryCache(),
});
