import { useMemo, ReactNode } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeOptions, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
// hooks
import useSettings from '../hooks/useSettings';
//
import palette from './palette';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import typography from 'src/theme/typography';

// typographyのvariantを拡張
declare module '@mui/material/styles' {
  interface TypographyVariants {
    headlineL: React.CSSProperties;
    headlineM: React.CSSProperties;
    titleL: React.CSSProperties;
    titleM: React.CSSProperties;
    titleS: React.CSSProperties;
    titleXS: React.CSSProperties;
    textL: React.CSSProperties;
    textM: React.CSSProperties;
    textS: React.CSSProperties;
    buttonL: React.CSSProperties;
    buttonM: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    headlineL: React.CSSProperties;
    headlineM: React.CSSProperties;
    titleL: React.CSSProperties;
    titleM: React.CSSProperties;
    titleS: React.CSSProperties;
    titleXS: React.CSSProperties;
    textL: React.CSSProperties;
    textM: React.CSSProperties;
    textS: React.CSSProperties;
    buttonL: React.CSSProperties;
    buttonM: React.CSSProperties;
  }
}

// TypographyのvariantのpropsにmyVariantを上書き
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    headlineL: true;
    headlineM: true;
    titleL: true;
    titleM: true;
    titleS: true;
    titleXS: true;
    textL: true;
    textM: true;
    textS: true;
    buttonL: true;
    buttonM: true;
  }
}

type Props = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 4 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, themeDirection]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}
