import { useTranslation } from 'react-i18next';
// @mui
import { enUS, deDE, frFR, jaJP } from '@mui/material/locale';

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_en.svg',
  },
  {
    label: 'German',
    value: 'de',
    systemValue: deDE,
    icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_de.svg',
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_fr.svg',
  },
  {
    label: '日本語',
    value: 'ja',
    systemValue: jaJP,
    icon: 'https://www.pngfind.com/pngs/m/63-634400_japanese-flag-sticker-japan-national-flag-hd-png.png',
  },
];

export default function useLocales() {
  const { i18n, t } = useTranslation();
  // const langStorage = localStorage.getItem('i18nextLng');createTheme(defaultTheme, currentLang.systemValue);

  const currentLang = LANGS[3];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    t,
    currentLang,
    allLang: LANGS,
  };
}
