import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetOrganizationQuery, GetTeamQuery, User } from 'src/API';

export type StateType = {
  user: User | null | undefined;
  team: GetTeamQuery['getTeam'];
  organization: GetOrganizationQuery['getOrganization'];
  ui: {
    [key: string]: boolean;
  };
  admin: {
    isGlobalAdmin: boolean;
    isAdmin: boolean;
  };
  network: string;
  snackbarMessage: {
    view?: 'snack' | 'dialog';
    message: string;
    type?: 'error' | 'warning' | 'info' | 'success' | 'authError' | 'versionUp' | 'reload';
    onPress?: () => void;
  } | null;
  globalMode: boolean;
  showMenu: boolean;
  showLeftMenu: boolean;
  previous: boolean;
  screen: string;
  notificationBadge: number;
};

const initialState: StateType = {
  user: null,
  team: null,
  organization: null,
  admin: {
    isGlobalAdmin: false,
    isAdmin: false,
  },
  network: 'online',
  snackbarMessage: null,
  globalMode: false,
  showMenu: false,
  showLeftMenu: true,
  previous: false,
  screen: '',
  notificationBadge: 0,
  ui: {},
};

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setGlobalMode(state, action: PayloadAction<boolean>) {
      state.globalMode = action.payload;
    },
    setOrganization(state, action: PayloadAction<GetOrganizationQuery['getOrganization']>) {
      state.organization = action.payload;
    },
    setAdmin(state, action: PayloadAction<StateType['admin']>) {
      state.admin = action.payload;
    },
    setNetwork(state, action: PayloadAction<StateType['network']>) {
      state.network = action.payload;
    },

    setUser(state, action: PayloadAction<StateType['user']>) {
      state.user = action.payload;
    },
    setTeam(state, action: PayloadAction<StateType['team']>) {
      // delete team.users;
      state.team = action.payload;
    },
    setShowMenu(state, action: PayloadAction<boolean>) {
      state.showMenu = action.payload;
    },
    setShowLeftMenu(state, action: PayloadAction<boolean>) {
      state.showLeftMenu = action.payload;
    },
    setScreen(state, action: PayloadAction<string>) {
      state.screen = action.payload;
    },
    setSnackbarMessage(state, action: PayloadAction<StateType['snackbarMessage']>) {
      if (state?.snackbarMessage?.message !== action.payload?.message) {
        state.snackbarMessage = action.payload;
      }
    },
    resetSnackbarMessage(state) {
      state.snackbarMessage = null;
    },
    setNotificationBadge(state, action: PayloadAction<number>) {
      state.notificationBadge = action.payload;
    },
    setUiState(state, action: PayloadAction<{ key: string; value: boolean }>) {
      const { key, value } = action.payload;
      state.ui = { ...state.ui, [key]: value };
    },
  },
});

export const currentApp = (state: { app: any }) => state.app;
export const currentAppUser = (state: { app: { user: any } }) => state?.app?.user;

// omit slice logic
export default app;
