import { createSlice } from '@reduxjs/toolkit';
type PageState = {
  isGoalCreateOpenModal: boolean;
  isGoalDetailOpenModal: boolean;
  goalID: string;
};

const initialState: PageState = {
  isGoalCreateOpenModal: false,
  isGoalDetailOpenModal: false,
  goalID: '',
};

const slice = createSlice({
  name: 'goal',
  initialState,
  reducers: {
    openCreateModal(state) {
      state.isGoalCreateOpenModal = true;
    },
    closeCreateModal(state) {
      state.isGoalCreateOpenModal = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openCreateModal, closeCreateModal } = slice.actions;
