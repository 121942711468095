/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable  */
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, Auth } from 'aws-amplify';
import { pascalCase } from 'change-case';
import gql from 'graphql-tag';
import { merge } from 'lodash';

import * as _queries from '../cgraphql/queries';
import app from '../redux/slices/appSlice';
import * as _mutations from '../graphql/mutations';
import { store } from '../redux/store';
import { client } from './apolloClient';

type StringKeyObject = {
  // 今回はstring
  [key: string]: string;
};

const queries: StringKeyObject = _queries;
const mutations: StringKeyObject = _mutations;

export const createClient = () => {
  return client;
};

export async function addToGroup(username: string, groupname: string) {
  const apiName = 'AdminQueries';
  const path = '/addUserToGroup';
  const myInit = {
    body: {
      username,
      groupname,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  return await API.post(apiName, path, myInit);
}

export async function removeUserFromGroup(username: string, groupname: string) {
  const apiName = 'AdminQueries';
  const path = '/removeUserFromGroup';
  const myInit = {
    body: {
      username,
      groupname,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  return await API.post(apiName, path, myInit);
}

export function getGroup() {
  try {
    const app = store.getState().app;
    return {
      organizationGroup: app?.user?.organizationGroup ?? '',
      adminGroup: app?.user?.adminGroup ?? '',
    };
  } catch (error) {
    console.error('getGroup is not working');
    throw error;
  }
}

export async function matchGroup(ckGroup: string) {
  const cuser = await Auth.currentAuthenticatedUser();

  const groups = cuser.signInUserSession.accessToken.payload['cognito:groups'];

  if (groups) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const match = groups.filter((group: string) => group.includes(ckGroup));
    return match.length > 0;
  } else {
    return false;
  }
}

export async function isOrgGroup() {
  const cognito = await Auth.currentUserInfo();
  const cuser = await Auth.currentAuthenticatedUser();
  const groups = cuser.signInUserSession.accessToken.payload['cognito:groups'];
  const organization = cognito.attributes['custom:organization'];

  if (organization && groups) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const match = groups.filter((group: string) => group === organization);
    return match.length > 0;
  } else {
    return false;
  }
}

export async function isAdminGroup() {
  const app = store.getState().app;
  if (app.user) {
    return matchGroup(app.user.adminGroup);
  }
  console.error('isAdminGroup is not working');

  return false;
}

/**
 * group 権限があるかどうかのチェック
 * @param organizationGroup
 * @returns
 */
export function checkOrganizationGroup(organizationGroup: string) {
  const { app } = store.getState();
  if (app.user && organizationGroup) {
    return app.user.organizationGroup === organizationGroup;
  }
  // 組織設定がない場合は、チェックしない。
  return true;
}

export async function getQuery<Result>(target: string, id: string, noError = false) {
  const queryName = `get${pascalCase(target)}`;
  try {
    const res = (await client.query({
      query: gql(queries[queryName]),
      variables: { id },
    })) as GraphQLResult<Result>;

    return res;
  } catch (error) {
    if (!noError) {
      handlingError(error, target);
      throw error;
    }
  }
}

export async function listQuery<Result, Params>(
  target: string,
  variable?: Params,
  authMode?: string
) {
  const queryName = `list${pascalCase(target)}s`;

  try {
    const mergedVariable = merge(variable, {
      filter: { delete: { ne: true } },
    });
    const res = await client.query<Result, Params>({
      query: gql(queries[queryName]),
      variables: mergedVariable,
      // @ts-ignore
      authMode,
    });

    // @ts-ignore
    await getNextData<Result, Params>(res, queryName, mergedVariable, authMode);

    return res;
  } catch (error) {
    handlingError(error, target);
    throw error;
  }
}

export async function listPublicQuery<Result, Params>({
  query,
  target,
  variables,
}: {
  query?: string;
  target: string;
  variables?: Params;
}) {
  const mergedVariable = merge(variables, {
    filter: { delete: { ne: true } },
  }) as Params;

  const queryName = `list${pascalCase(target)}s`;
  try {
    const res = await client.query<Result, Params>({
      query: gql(queries[queryName]),
      variables: mergedVariable,
    });

    return res;
  } catch (error) {
    store.dispatch(
      app.actions.setSnackbarMessage({
        message: '通信エラーが発生しました',
        type: 'error',
      })
    );
    console.error(error);
    throw error;
  }
}

function handlingError(error: any, target: string) {
  if (error === 'No current user') {
    store.dispatch(
      app.actions.setSnackbarMessage({
        message: 'ログアウトされています。',
        type: 'authError',
      })
    );
  } else {
    store.dispatch(
      app.actions.setSnackbarMessage({
        message: '通信エラーが発生しました',
        type: 'error',
      })
    );
  }
  // Sentry.captureException(error);

  console.error(`target ${target}`, error);
}

/**
 * 問い合わせた結果に nextToken が入っていても続きを処理せず listQuery の結果を返す
 * @param target
 * @param variable
 */
export async function listQueryWithoutPagination<Result, Params>(
  target: string,
  variable?: Params
) {
  const queryName = `list${pascalCase(target)}s`;

  try {
    const mergedVariable = merge(variable, {
      filter: { delete: { ne: true } },
    });
    const res = await client.query<Result, Params>({
      query: gql(queries[queryName]),
      variables: mergedVariable,
    });

    return res;
  } catch (error) {
    store.dispatch(
      app.actions.setSnackbarMessage({
        message: '通信エラーが発生しました',
        type: 'error',
      })
    );
    console.error(error);
    throw error;
  }
}

/**
 * nextToken をみて値があれば res にいれる。
 * @param res
 * @param queryName
 * @param mergedVariable
 * @returns
 */
async function getNextData<Result, Params>(
  res: GraphQLResult<Result>,
  queryName: string,
  mergedVariable: Params & { filter: { delete: { ne: boolean } } },
  authMode?: string
) {
  let nextRes;
  let nextItems;

  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const resData = res?.data[queryName];

  if (resData) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let nextToken = resData.nextToken;

    // items が空の場合は追加
    if (!resData.items) {
      resData.items = [];
    }

    const resItems = resData.items;

    while (nextToken) {
      //@ts-ignore
      mergedVariable.nextToken = nextToken;

      // eslint-disable-next-line no-await-in-loop
      nextRes = await client.query<Result, Params>({
        query: gql(queries[queryName]),
        variables: mergedVariable,
        // @ts-ignore
        authMode,
      });

      // 続きのデータ
      //@ts-ignore
      const nextData = nextRes?.data[queryName];

      nextToken = nextData?.nextToken;
      nextItems = nextData?.items;
      if (nextItems?.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        resItems.push(...nextItems);
      }
    }
  }

  return res;
}

export async function keyQuery<Result, Params>(
  queryName: string,
  variable: Params,
  noDelete = false,
  authMode?: string
) {
  try {
    let mergedVariable;
    if (noDelete) {
      mergedVariable = merge(variable, {
        filter: { delete: { ne: true } },
      });
    } else {
      mergedVariable = variable;
    }

    const res = await client.query<Result, Params>({
      query: gql(queries[queryName]),
      variables: mergedVariable,
    });

    await getNextData<Result, Params & { filter: { delete: { ne: boolean } } }>(
      // @ts-ignore
      res,
      queryName,
      // @ts-ignore
      mergedVariable,
      authMode
    );

    return res;
  } catch (error) {
    handlingError(error, queryName);
    throw error;
  }
}

/**
 * 問い合わせた結果に nextToken が入っていても続きを処理せず keyQuery の結果を返す
 * @param queryName
 * @param variable
 * @param noDelete
 */
export async function keyQueryWithoutPagination<Result, Params>(
  queryName: string,
  variable: Params,
  noDelete = false
) {
  try {
    let mergedVariable;
    if (noDelete) {
      mergedVariable = merge(variable, {
        filter: { delete: { ne: true } },
      });
    } else {
      mergedVariable = variable;
    }

    const res = await client.query<Result, Params>({
      query: gql(queries[queryName]),
      variables: mergedVariable,
    });

    return res;
  } catch (error) {
    handlingError(error, queryName);
    throw error;
  }
}

export function getInput<Params>(variable: any, options?: CreateOptions) {
  let input;

  if (options?.noGroup) {
    input = {
      ...variable,
    };
  } else {
    const { organizationGroup, adminGroup } = getGroup();
    if (organizationGroup && adminGroup) {
      const groups = options?.private ? { adminGroup } : { organizationGroup, adminGroup };
      input = { ...variable, ...groups };
    } else {
      input = {
        ...variable,
      };
      console.warn('getInput: group を取得できませんでした。');
    }
  }

  if (variable.id && !options?.createByID) {
    input.updatedAt = new Date().toISOString();
  } else {
    input.createdAt = new Date().toISOString();
    input.updatedAt = new Date().toISOString();
  }
  return input as Params;
}

export async function getQueryName<Params>(
  target: string,
  variable: Params,
  options?: CreateOptions
) {
  //  @ts-ignore createの場合に型が合わない
  const id = variable.id;
  let action = 'create';
  const queryName = `get${pascalCase(target)}`;

  const createByID = options?.createByID;

  if (id && !createByID) {
    const value = await getQuery(target, id, true);
    if (value) {
      //@ts-ignore
      const data = value.data[queryName];

      if (data) {
        action = 'update';
      }
    }
  }
  return `${action}${pascalCase(target)}`;
}

type CreateOptions = {
  noGroup?: boolean;
  private?: boolean;
  createByID?: boolean;
};

type InputMutation<Params> = {
  input: Params;
};

export async function createQuery<Result, Params>(
  target: string,
  variable: Partial<Params>,
  options?: CreateOptions
) {
  if (options?.noGroup && options?.private) {
    console.warn('noGroup と private を両方同時に指定すると noGroup を優先します。');
  }
  //  @ts-ignore createの場合に型が合わない。queryNameがない場合の処理は実装済み
  const queryName = await getQueryName<Params>(target, variable);
  const client = await createClient();
  if (!queryName) {
    console.error('target:', target, 'variable:', variable);
    throw new Error('createQuery');
  }
  const input = getInput<Params>(variable, options);

  try {
    const res = (await client.mutate<Result, InputMutation<Params>>({
      mutation: gql(mutations[queryName]),
      variables: { input },
    })) as GraphQLResult<Result>;
    return res;
  } catch (error) {
    handlingError(error, target);

    throw error;
  }
}

export async function createQueryData<Result, Params>(
  target: string,
  variable: Partial<Params>,
  options?: CreateOptions
) {
  if (options?.noGroup && options?.private) {
    console.warn('noGroup と private を両方同時に指定すると noGroup を優先します。');
  }
  //  @ts-ignore createの場合に型が合わない。queryNameがない場合の処理は実装済み
  const queryName = await getQueryName<Params>(target, variable);
  const client = await createClient();
  if (!queryName) {
    console.error('target:', target, 'variable:', variable);
    throw new Error('createQuery');
  }
  const input = getInput<Params>(variable, options);

  try {
    const res = (await client.mutate<Result, InputMutation<Params>>({
      mutation: gql(mutations[queryName]),
      variables: { input },
    })) as GraphQLResult<Result>;
    // @ts-ignore

    return res?.data[queryName];
  } catch (error) {
    handlingError(error, target);

    throw error;
  }
}

export async function doFunction<Result, Params>(funcName: string, variable: Params) {
  const queryName = funcName;
  const client = await createClient();
  try {
    const res = (await client.mutate<Result, Params>({
      mutation: gql(mutations[queryName]),
      variables: variable,
    })) as GraphQLResult<Result>;

    return res;
  } catch (error) {
    handlingError(error, funcName);
    console.error(error);
    throw error;
  }
}

export async function createQueries<Result, Params>(
  target: string,
  variables: Params[],
  options?: CreateOptions
) {
  const tasks = [] as Promise<any>[];

  variables.forEach(function (variable) {
    const task = createQuery<Result, Params>(target, variable, options);

    tasks.push(task);
  });

  try {
    return await Promise.all(tasks);
  } catch (error) {
    handlingError(error, target);

    console.error(target, error);
  }
}

type DeleteParams = {
  input: {
    id: string;
    // expectedVersion?: number;
  };
};

export async function deleteQuery<Result>(target: string, id: string, expectedVersion?: number) {
  const queryName = `delete${pascalCase(target)}`;
  const input = expectedVersion ? { id, expectedVersion } : { id };

  try {
    const client = await createClient();
    const res = (await client.mutate<Result, DeleteParams>({
      mutation: gql(mutations[queryName]),
      variables: { input },
    })) as GraphQLResult<Result>;

    return res;
  } catch (error) {
    handlingError(error, target);

    console.error(error);
    throw error;
  }
}

export async function softDeleteQuery<Result>(
  target: string,
  id: string,
  expectedVersion?: number
) {
  let res;
  const params = expectedVersion
    ? {
        id,
        delete: true,
        updatedAt: new Date().toISOString(),
        expectedVersion,
      }
    : {
        id,
        updatedAt: new Date().toISOString(),
        delete: true,
      };
  try {
    // @ts-ignore
    res = await createQuery<Result, { id: string; delete: boolean }>(target, params);

    return res;
  } catch (error) {
    handlingError(error, target);
    console.error(error);
    throw error;
  }
}
