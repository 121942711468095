import { useEffect, ReactNode } from 'react';
// rtl
import rtlPlugin from 'stylis-plugin-rtl';
// emotion
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
// @mui
import { useTheme } from '@mui/material/styles';
import useAuth from 'src/hooks/useAuth';
import { useRouter } from 'next/router';
import { PATH_AUTH } from 'src/routes/paths';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function RtlLayout({ children }: Props) {
  const theme = useTheme();
  const { user, logout } = useAuth();
  const router = useRouter();

  useEffect(() => {
    document.dir = theme.direction;
  }, [theme.direction]);

  const cacheRtl = createCache({
    key: theme.direction === 'rtl' ? 'rtl' : 'css',
    stylisPlugins: theme.direction === 'rtl' ? [rtlPlugin] : [],
  });

  // タイムアウトフラグとリロードフラグ
  let FLAG_TIMEOUT = false;
  let FLAG_RELOAD = false;

  // 最終操作時間を入れる
  let LASTTIME = Date.now();

  //--------------------------------------
  // イベントに仕込む
  //--------------------------------------
  // 指定の操作をすると最終操作時間を更新
  [
    'click',
    'mousemove',
    // ここに監視するイベントを列挙
  ].forEach((type) => {
    window.addEventListener(type, () => {
      if (user) {
        LASTTIME = Date.now();
      }
    });
  });

  if (user) {
    //--------------------------------------
    // 監視する
    //--------------------------------------
    const timerid = setInterval(() => {
      // 1時間以上、無操作か判定してリロード
      FLAG_RELOAD = Date.now() - LASTTIME > 3600 * 1000;

      if (FLAG_RELOAD) {
        clearInterval(timerid);
        location.reload();
      }

      // 10時間以上、無操作か判定してログアウト
      FLAG_TIMEOUT = Date.now() - LASTTIME > 36000 * 1000;

      if (FLAG_TIMEOUT) {
        clearInterval(timerid);
        logout();
        router.replace(PATH_AUTH.login);
      }
    }, 1000);
  }

  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
}
