/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://kllknns218.execute-api.ap-northeast-1.amazonaws.com/prodfour",
            "region": "ap-northeast-1"
        },
        {
            "name": "OutlookCalendarWebhook",
            "endpoint": "https://voxjan0vx7.execute-api.ap-northeast-1.amazonaws.com/prodfour",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ib3dcazy3raendzin7pnyjrmy4.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-elng6d2cdfev3d5keyzp7j4t4u",
    "aws_cognito_identity_pool_id": "ap-northeast-1:0bfd615d-296b-4b79-9483-9516845fff40",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_1uIrlXoK9",
    "aws_user_pools_web_client_id": "3v8r4m8fraiv0dub162117jn1t",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "ORGANIZATION"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "coteam2-dev101918-devfour90636-prodfour",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
