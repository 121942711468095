import { Client, PageCollection, PageIterator } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { Calendar, Event, User } from 'microsoft-graph';

let graphClient: Client | undefined = undefined;

export const ensureClient = (authProvider: AuthCodeMSALBrowserAuthenticationProvider) => {
  if (!graphClient) {
    graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
    });
  }

  return graphClient;
};

export const getMicrosoftUser = async (
  authProvider: AuthCodeMSALBrowserAuthenticationProvider
): Promise<User> => {
  ensureClient(authProvider);
  try {
    const user: User = await graphClient!.api('/me').get();
    return user;
  } catch (error) {
    throw new Error(`retrieve user error: ${error}`);
  }
};

export const getOutlookCalendars = async (
  authProvider: AuthCodeMSALBrowserAuthenticationProvider
) => {
  ensureClient(authProvider);

  try {
    const response: PageCollection = await graphClient!.api('/me/calendars').get();
    if (response['@odata.nextLink']) {
      const calendars: Calendar[] = [];

      const pageIterator = new PageIterator(graphClient!, response, (calendar) => {
        calendars.push(calendar);
        return true;
      });

      await pageIterator.iterate();

      return calendars;
    } else {
      return response.value;
    }
  } catch (error) {
    throw new Error(`retrieve calendar error: ${error}`);
  }
};

export const getOutlookEvents = async (
  authProvider: AuthCodeMSALBrowserAuthenticationProvider,
  calendarID: string
): Promise<Event[]> => {
  ensureClient(authProvider);
  try {
    const response: PageCollection = await graphClient!
      .api(`me/calendar/events`)
      .header('Prefer', 'outlook.timezone="Tokyo Standard Time"')
      .get();
    if (response['@odata.nextLink']) {
      const events: Event[] = [];

      const pageIterator = new PageIterator(graphClient!, response, (event) => {
        events.push(event);
        return true;
      });

      await pageIterator.iterate();

      return events;
    } else {
      return response.value;
    }
  } catch (error) {
    throw new Error(`retrieve calendar error: ${error}`);
  }
};
