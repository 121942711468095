import CustomDialog from './CustomDialog';

type ErrorDialogProps = {
  open: boolean;
  onClose: () => void;
};

export default function ErrorDialog({ open, onClose }: ErrorDialogProps) {
  return (
    <CustomDialog isOpen={open} onClose={onClose} title={'エラー警告'}>
      ご不便かけてしまい大変申し訳ございません、予期しないエラーが発生してしまいました。
      <br />
      再度アクセスしていただくと正常に動作する可能性がございます。
      <br />
      <br />
      もし同じエラーが発生してしまう場合は、お手数かけますが早期解決のため画面右下のチャットから起きている内容についてお問い合わせいただけますと助かります。こちらから折り返しメールにてご連絡させていただきます。
    </CustomDialog>
  );
}
