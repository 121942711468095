import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type StateType = {
  id?: string;
  userId?: string;
  onUpdate?: () => void;
  isOpened: boolean;
  isDeclined: boolean;
  isConfirmed: boolean;
};

export type PropsType = {
  id?: string;
  userId?: string;
  onUpdate?: () => void;
};

const initialOnoModalSliceState: StateType = {
  id: '',
  userId: '',
  onUpdate: () => {},
  isOpened: false,
  isDeclined: false,
  isConfirmed: false,
};

const onoModalSlice = createSlice({
  name: 'onoModal',
  initialState: initialOnoModalSliceState,
  reducers: {
    open: (state, action: PayloadAction<PropsType>) => {
      state.id = action.payload.id;
      state.userId = action.payload.userId;
      state.onUpdate = action.payload.onUpdate;
      state.isOpened = true;
      state.isDeclined = false;
      state.isConfirmed = false;
    },
    confirm: (state, action: PayloadAction<PropsType>) => {
      state.id = action.payload.id;
      state.userId = action.payload.userId;
      state.onUpdate = action.payload.onUpdate;
      state.isOpened = false;
      state.isConfirmed = true;
    },
    decline: (state) => {
      state.isDeclined = true;
      state.isOpened = false;
    },
  },
});

export const onoModalActions = onoModalSlice.actions;

export default onoModalSlice;
