import { combineReducers } from 'redux';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
// slices
import userReducer from './slices/user';
import goalReducer from './slices/goal';
import appSlice from './slices/appSlice';
import onoModalSlice from './slices/onoModal';
import kanbanReducer from './slices/kanban';
import dashboardReducer from './slices/dashboard';

// ----------------------------------------------------------------------

const createNoopStorage = () => ({
  getItem() {
    return Promise.resolve(null);
  },
  setItem(_key: string, value: any) {
    return Promise.resolve(value);
  },
  removeItem() {
    return Promise.resolve();
  },
});

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  // page
  user: userReducer,
  // api
  dashborad: dashboardReducer,
  kanban: kanbanReducer,
  goal: goalReducer,
  onoModal: onoModalSlice.reducer,
  // minimals sample
  app: appSlice.reducer,
});

export { rootPersistConfig, rootReducer };
