import { createSlice } from '@reduxjs/toolkit';
import { PageState } from 'src/@types/dashboard';

// ----------------------------------------------------------------------

const initialState: PageState = {
  open: false,
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    openNavbar(state) {
      state.open = true;
    },
    closeNavbar(state) {
      state.open = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openNavbar, closeNavbar } = slice.actions;
