import { ReactNode, useRef } from 'react';
import { SnackbarProvider } from 'notistack';
import { useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
//
import { RiCheckLine } from 'react-icons/ri';
import { BUTTON_HOVER_SHADOWS } from 'src/theme/shadows';

// ----------------------------------------------------------------------

function SnackbarStyles() {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '#__next': {
          '& .SnackbarContent-root': {
            width: '100%',
            padding: '12px 16px',
            borderRadius: theme.shape.borderRadius,
            filter: BUTTON_HOVER_SHADOWS,
            [theme.breakpoints.up('md')]: {
              minWidth: 240,
            },
          },
          '& .SnackbarItem-message': {
            padding: 0,
            gap: '12px',
            fontWeight: theme.typography.fontWeightMedium,
          },
          '& .SnackbarItem-action': {
            marginRight: 0,
            color: theme.palette.action.active,
            '& svg': { width: 20, height: 20 },
          },
        },
      }}
    />
  );
}

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function NotistackProvider({ children }: Props) {
  const theme = useTheme();
  const notistackRef = useRef<any>(null);

  return (
    <>
      <SnackbarStyles />
      <SnackbarProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={5000}
        variant="success"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        style={{
          backgroundColor: '#2B2E43',
          color: theme.palette.text.contrast,
          fontSize: '16px',
          marginBottom: '12px',
        }}
        iconVariant={{
          info: <RiCheckLine size={20} />,
          success: <RiCheckLine size={20} />,
          warning: <RiCheckLine size={20} />,
          error: <RiCheckLine size={20} />,
        }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}
