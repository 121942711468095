import { Configuration, PublicClientApplication } from '@azure/msal-browser';

const isClient = typeof window !== 'undefined';
const clientId = process.env['NEXT_PUBLIC_OUTLOOK_CLIENT_ID'] ?? '';
const redirectUri = isClient ? window.location.origin : '';

const configuration: Configuration = {
  auth: {
    clientId,
    redirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
};

export const msalInstance = new PublicClientApplication(configuration);
