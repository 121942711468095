import { RiCloseFill } from 'react-icons/ri';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';

export const CUSTOM_DIALOG_CONTENT_HEIGHT = 'calc(100vh - 120px)';

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
    marginTop: '60px',
    marginBottom: '60px',
    maxHeight: CUSTOM_DIALOG_CONTENT_HEIGHT, // ビューポートの高さから120pxを引いた値を設定する
  },
}));

export type CustomDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  actions?: ReactNode; // ダイアログのフッターの部分、ボタンなどを設定する。この部分はスクロールされない。
  size?: 'small' | 'medium' | 'large';
  isContentPaddingNone?: boolean; // contentのpaddingをoffにするためのフラグ
};

export default function CustomDialog({
  isOpen,
  onClose,
  title,
  children,
  actions,
  size = 'small',
  isContentPaddingNone = false,
}: CustomDialogProps) {
  const theme = useTheme();

  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
      BackdropProps={{
        style: { backgroundColor: '#2B2E43', opacity: '50%' },
      }}
      sx={{
        backdropFilter: 'blur(4px)',
        '& .MuiDialog-paper': {
          width:
            size === 'small'
              ? '569px'
              : size === 'medium'
              ? '982px'
              : size === 'large'
              ? '1200px'
              : '',
        },
      }}
    >
      <Stack justifyContent="space-between" direction="row" padding="16px" alignItems="center">
        <DialogTitle style={{ justifyContent: 'space-between', width: 'full', padding: 0 }}>
          <Typography variant="titleL">{title}</Typography>
        </DialogTitle>
        <RiCloseFill
          onClick={onClose}
          role="button"
          aria-label="閉じる"
          style={{ cursor: 'pointer', width: '20px', fontSize: '20px' }}
        />
      </Stack>
      <DialogContent
        sx={{
          border: `1px solid ${theme.palette.grey[400]}`,
          '&.MuiDialogContent-root': {
            padding: isContentPaddingNone ? '0px' : '32px 20px 48px 20px',
          },
        }}
      >
        {children}
      </DialogContent>
      <DialogActions sx={{ p: '20px' }}>{actions}</DialogActions>
    </StyledDialog>
  );
}
