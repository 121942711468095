import { createContext, ReactNode, useCallback, useEffect, useReducer } from 'react';
// @types
import { Auth } from 'aws-amplify';
import { ActionMap, AmplifyAuthContextType, AuthState, AuthUser } from '../@types/auth';
// Amplify library
// ----------------------------------------------------------------------
import { CognitoUserInterface } from 'src/@types/types';
import { matchGroup } from 'src/utils/apiTypeUtil';

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  isAdmin: false,
  user: null,
  organization: null,
  jwtToken: null,
};

enum Types {
  auth = 'AUTHENTICATE',
  logout = 'LOGOUT',
}

type AwsAuthPayload = {
  [Types.auth]: {
    isInitialized: boolean;
    isAuthenticated: boolean;
    isAdmin: boolean;
    user: AuthUser;
    jwtToken: string | null;
  };
  [Types.logout]: undefined;
};

type AwsActions = ActionMap<AwsAuthPayload>[keyof ActionMap<AwsAuthPayload>];

const reducer = (state: AuthState, action: AwsActions) => {
  try {
    if (action.type === 'AUTHENTICATE') {
      const { isAuthenticated, isAdmin, user, jwtToken } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        isAdmin,
        user,
        organization: user?.attributes['custom:organization'] ?? null,
        jwtToken,
      };
    }
    if (action.type === 'LOGOUT') {
      return {
        ...state,
        isAuthenticated: false,
        isAdmin: false,
        user: null,
        jwtToken: null,
      };
    }
    return state;
  } catch (error) {
    Auth.signOut();

    return {
      ...state,
      isAuthenticated: false,
      isAdmin: false,
      user: null,
      jwtToken: null,
    };
  }
};

const AuthContext = createContext<AmplifyAuthContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getUserAttributes = useCallback(async () => {
    const cognito = (await Auth.currentUserInfo()) as CognitoUserInterface;
    return cognito;
  }, []);

  const getSession = useCallback(async () => {
    const jwtToken = null;

    const cognitoUser = await getUserAttributes();

    const isGlobalAdmin = await matchGroup('GlobalAdmins');
    if (isGlobalAdmin) {
      dispatch({
        type: Types.auth,
        payload: {
          isInitialized: true,
          isAuthenticated: true,
          isAdmin: true,
          jwtToken,
          user: null,
        },
      });
    } else if (cognitoUser) {
      dispatch({
        type: Types.auth,
        payload: {
          isInitialized: true,
          isAuthenticated: true,
          isAdmin: false,
          user: cognitoUser,
          jwtToken,
        },
      });
    } else {
      dispatch({
        type: Types.auth,
        payload: {
          isInitialized: true,
          isAuthenticated: false,
          isAdmin: false,
          user: null,
          jwtToken: null,
        },
      });
    }
  }, [getUserAttributes]);

  const initial = useCallback(async () => {
    try {
      await getSession();
    } catch (error) {
      dispatch({
        type: Types.auth,
        payload: {
          isInitialized: true,
          isAuthenticated: false,
          isAdmin: false,
          user: null,
          jwtToken: null,
        },
      });
    }
  }, [getSession]);

  useEffect(() => {
    initial();
  }, [initial]);

  const login = useCallback(
    async (email: string, password: string) => {
      try {
        await Auth.signIn(email, password);
        await getSession();
      } catch (error) {
        console.error('Login error:', error);
        dispatch({
          type: Types.auth,
          payload: {
            isInitialized: true,
            isAuthenticated: false,
            isAdmin: false,
            user: null,
            jwtToken: null,
          },
        });
        throw error;
      }
    },
    [getSession]
  );

  const logout = async () => {
    await Auth.signOut();
    dispatch({ type: Types.logout });
    localStorage.clear();
  };

  const register = async (
    username: string,
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    organization: string
  ) => {
    await Auth.signUp({
      username: username,
      password,
      attributes: {
        email,
        given_name: firstName,
        family_name: lastName,
        'custom:organization': organization,
      },
    });
  };

  const verify = async (email: string, code: string) => {
    await Auth.confirmSignUp(email, code);
  };

  const resetPassword = async (email: string) => {
    await Auth.forgotPassword(email);
  };

  const updatePassword = async (email: string, code: string, password: string) => {
    await Auth.forgotPasswordSubmit(email, code, password);
  };

  const updateInviteUserPassword = async (
    userID: string,
    tempPassword: string,
    newPassword: string
  ) => {
    const user = (await Auth.signIn(userID, tempPassword)) as CognitoUserInterface;

    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      await Auth.completeNewPassword(user, newPassword, {});
      await getSession();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'amplify',
        login,
        register,
        logout,
        verify,
        resetPassword,
        updatePassword,
        updateInviteUserPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
