// ----------------------------------------------------------------------
export function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_APP = '/app/[organizationName]';
const ROOTS_GLOBAL = '/global';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  updatePassword: path(ROOTS_AUTH, '/update-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  account: path(ROOTS_AUTH, '/account'),
  awaitingApproval: path(ROOTS_AUTH, '/awaiting-approval'),
};

export const PATH_APP = {
  root: ROOTS_APP,
  me: {
    root: path(ROOTS_APP, '/me'),
    goals: path(ROOTS_APP, '/me/goals'),
    meetings: {
      root: path(ROOTS_APP, '/me/meetings'),
      meeting: {
        root: path(ROOTS_APP, '/me/meetings/[meetingID]'),
      },
    },
    oneOnOnes: {
      root: path(ROOTS_APP, '/me/oneOnOne'),
      oneOnOne: path(ROOTS_APP, '/me/oneOnOne/[oneOnOneSettingID]/[oneOnOneID]'),
    },

    setting: {
      profile: path(ROOTS_APP, '/me/setting/profile'),
      notification: path(ROOTS_APP, '/me/setting/notification'),
    },
    reviews: {
      root: path(ROOTS_APP, '/me/reviews'),
      review: {
        individualReviewSheet: path(
          ROOTS_APP,
          '/me/reviews/[reviewID]/individualReviewSheet/[reviewerID]'
        ),
        reviewResult: path(ROOTS_APP, '/me/reviews/[reviewID]/reviewResult/[reviewerID]'),
      },
    },
  },
  organization: {
    root: path(ROOTS_APP, '/organization'),
    goals: path(ROOTS_APP, '/organization/goals'),
    meetings: path(ROOTS_APP, '/organization/meetings'),
    oneOnOnes: path(ROOTS_APP, '/organization/oneOnOnes'),
    teams: {
      root: path(ROOTS_APP, '/organization/teams'),
      team: {
        root: path(ROOTS_APP, '/organization/teams/[teamID]'),
        goals: path(ROOTS_APP, '/organization/teams/[teamID]/goals'),
      },
    },
    members: {
      root: path(ROOTS_APP, '/organization/members'),
      member: {
        root: path(ROOTS_APP, '/organization/members/[userID]'),
        meetings: path(ROOTS_APP, '/organization/members/[userID]/meetings'),
        reviewResults: path(ROOTS_APP, '/organization/members/[userID]/reviewResults'),
        oneOnOnes: path(ROOTS_APP, '/organization/members/[userID]/oneOnOnes'),
      },
    },
  },
  admin: {
    setting: {
      root: path(ROOTS_APP, '/admin/setting'),
      analytics: {
        root: path(ROOTS_APP, '/admin/setting/analytics'),
        meeting: {
          root: path(ROOTS_APP, '/admin/setting/analytics/meeting'),
          agenda: path(ROOTS_APP, '/admin/setting/analytics/meeting/agenda'),
        },
      },
      profile: path(ROOTS_APP, '/admin/setting/profile'),
      users: {
        root: path(ROOTS_APP, '/admin/setting/users'),
        invite: path(ROOTS_APP, '/admin/setting/users/invite'),
        user: {
          root: path(ROOTS_APP, '/admin/setting/users/[userID]'),
          profile: path(ROOTS_APP, '/admin/setting/users/[userID]/profile'),
          notification: path(ROOTS_APP, '/admin/setting/users/[userID]/notification'),
        },
      },
      teams: {
        root: path(ROOTS_APP, '/admin/setting/teams'),
        team: {
          root: path(ROOTS_APP, '/admin/setting/teams/[teamID]'),
          profile: path(ROOTS_APP, '/admin/setting/teams/[teamID]/profile'),
          users: path(ROOTS_APP, '/admin/setting/teams/[teamID]/users'),
        },
        create: path(ROOTS_APP, '/admin/setting/teams/create'),
      },
      notification: path(ROOTS_APP, '/admin/setting/notification'),
      organizationAgenda: path(ROOTS_APP, '/admin/setting/organizationAgenda'),
    },
    reviewSetting: {
      review: {
        root: path(ROOTS_APP, '/admin/reviewSetting/[reviewID]'),
        reviewees: path(ROOTS_APP, '/admin/reviewSetting/[reviewID]/reviewees'),
        reviewers: path(ROOTS_APP, '/admin/reviewSetting/[reviewID]/reviewers'),
        reviewScoring: path(ROOTS_APP, '/admin/reviewSetting/[reviewID]/reviewScoring'),
        reviewScoringInput: path(ROOTS_APP, '/admin/reviewSetting/[reviewID]/reviewScoringInput'),
        reviewShare: path(ROOTS_APP, '/admin/reviewSetting/[reviewID]/reviewShare'),
        reviewResult: path(ROOTS_APP, '/admin/reviewSetting/[reviewID]/reviewResult'),
        reviewResultDetail: path(ROOTS_APP, '/admin/reviewSetting/[reviewID]/reviewResultDetail'),
      },
      gradeMatrix: {
        root: path(ROOTS_APP, '/admin/reviewSetting/gradeMatrix'),
        detail: {
          root: path(ROOTS_APP, '/admin/reviewSetting/gradeMatrix/[gradeMatrixID]'),
        },
      },
      template: path(ROOTS_APP, '/admin/reviewSetting/template'),
      question: path(ROOTS_APP, '/admin/reviewSetting/question'),
      customReview: path(ROOTS_APP, '/admin/reviewSetting/customReview'),
      reviewTemplate: path(ROOTS_APP, '/admin/reviewSetting/reviewTemplate'),
      reviewList: path(ROOTS_APP, '/admin/reviewSetting/reviewList'),
    },
    meetings: {
      root: path(ROOTS_APP, '/admin/meetings'),
    },
    oneOnOnes: path(ROOTS_APP, '/admin/oneOnOnes'),
  },
};

export const PATH_GLOBAL = {
  root: ROOTS_GLOBAL,
  organizations: {
    root: path(ROOTS_GLOBAL, '/organizations'),
    new: path(ROOTS_GLOBAL, '/organizations/new'),
    organization: {
      profile: path(ROOTS_GLOBAL, '/organizations/[organizationID]/profile'),
      users: {
        root: path(ROOTS_GLOBAL, '/organizations/[organizationID]/users'),
        new: path(ROOTS_GLOBAL, '/organizations/[organizationID]/users/new'),
        user: {
          root: path(ROOTS_GLOBAL, '/organizations/[organizationID]/users/[userID]'),
          profile: path(ROOTS_GLOBAL, '/organizations/[organizationID]/users/[userID]/profile'),
          notifications: path(
            ROOTS_GLOBAL,
            '/organizations/[organizationID]/users/[userID]/notifications'
          ),
        },
      },
      teams: {
        root: path(ROOTS_GLOBAL, '/organizations/[organizationID]/teams'),
        new: path(ROOTS_GLOBAL, '/organizations/[organizationID]/teams/new'),
        team: {
          root: path(ROOTS_GLOBAL, '/organizations/[organizationID]/teams/[teamID]'),
          setting: path(ROOTS_GLOBAL, '/organizations/[organizationID]/teams/[teamID]/setting'),
        },
      },
      analytics: {
        root: path(ROOTS_GLOBAL, '/organizations/[organizationID]/analytics'),
        meeting: {
          root: path(ROOTS_GLOBAL, '/organizations/[organizationID]/analytics/meeting'),
          agenda: path(ROOTS_GLOBAL, '/organizations/[organizationID]/analytics/meeting/agenda'),
        },
        goal: {
          root: path(ROOTS_GLOBAL, '/organizations/[organizationID]/analytics/goal'),
          average: path(ROOTS_GLOBAL, '/organizations/[organizationID]/analytics/goal/average'),
          updateRate: path(
            ROOTS_GLOBAL,
            '/organizations/[organizationID]/analytics/goal/update-rate'
          ),
        },
      },
    },
  },
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

export const PATH_HELP = 'https://oinc.notion.site/38fc01fb073149869baabe55a8dff157';
export const PATH_OPINION = 'https://share.hsforms.com/1o4ktQ_U_Qwyp9Kw7K_8c4A587qx';
