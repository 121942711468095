import React, { useContext, createContext, useState, useEffect } from 'react';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Client } from '@microsoft/microsoft-graph-client';
import { User } from 'microsoft-graph';
import { useError } from './ErrorContext';
import { getMicrosoftUser } from 'src/hooks/api/outlook';

type MSGraphContext = {
  microsoftUser?: User;
  signIn: () => void;
  signOut: () => void;
  authProvider?: AuthCodeMSALBrowserAuthenticationProvider;
};

const MSGraphContext = createContext<MSGraphContext>({
  microsoftUser: undefined,
  signIn: () => {},
  signOut: () => {},
  authProvider: undefined,
});

export function useMSGraphContext(): MSGraphContext {
  return useContext(MSGraphContext);
}

interface ProvideMSGraphContextProps {
  children: React.ReactNode;
}

export default function ProvideMSGraphContext({ children }: ProvideMSGraphContextProps) {
  const auth = useProvideMSGraphContext();
  return <MSGraphContext.Provider value={auth}>{children}</MSGraphContext.Provider>;
}

function useProvideMSGraphContext() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [microsoftUser, setMicrosoftUser] = useState<User | undefined>(undefined);

  const { setErrorDialog } = useError();
  const loginRequest = {
    scopes: ['user.read', 'calendars.read'],
  };

  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    instance as PublicClientApplication,
    {
      account: instance.getActiveAccount()!,
      scopes: loginRequest.scopes,
      interactionType: InteractionType.Popup,
    }
  );

  const graphClient = Client.initWithMiddleware({
    authProvider,
  });

  useEffect(() => {
    const checkUser = async () => {
      if (!microsoftUser && isAuthenticated) {
        try {
          const account = instance.getActiveAccount();
          if (account) {
            const user = await getMicrosoftUser(authProvider);

            if (user) {
              setMicrosoftUser({
                displayName: user.displayName || '',
                mail: user.mail || user.userPrincipalName || '',
              });
            }
          }
        } catch (error) {
          setErrorDialog(true);
          console.error(error);
          signOut();
        }
      }
    };
    checkUser();
  });

  const signIn = async () => {
    try {
      const response = await instance.loginPopup({
        scopes: loginRequest.scopes,
        prompt: 'select_account',
      });
      instance.setActiveAccount(response.account);
    } catch (e) {
      setErrorDialog(true);
      console.error('sign in error:', e);
    }
  };

  const signOut = async () => {
    try {
      await instance.logoutPopup();

      setMicrosoftUser(undefined);
    } catch (e) {
      console.error('sign out error:', e);
    }
  };

  return {
    microsoftUser,
    signIn,
    signOut,
    authProvider,
    graphClient,
  };
}
