import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function DataGrid(theme: Theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          boxShadow: 2,
          padding: '16px',
          border: `1px solid ${theme.palette.grey[300]}`,
          '& .MuiTablePagination-root': {
            borderTop: 0,
            justifyContent: 'space-between',
          },
          '& .MuiDataGrid-toolbarContainer': {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.neutral,
            '& .MuiButton-root': {
              marginRight: theme.spacing(1.5),
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
          },
          // ヘッダーのポインターイベントを無効化(これをするとソートができなくなる。)
          '& .MuiDataGrid-columnHeader': {
            pointerEvents: 'none',
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: theme.palette.background.neutral,
            },
          },
          // 行がホバーされた時に背景色が変わらないようにする
          '& .MuiDataGrid-row': {
            '&:hover': {
              backgroundColor: theme.palette.background.default,
            },
          },
          '& .MuiDataGrid-cell, .MuiDataGrid-columnsContainer': {
            // borderBottom: `1px solid ${theme.palette.grey[300]}`,
          },
          // カラムのセパレーターを表示しないようにする
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeader[data-field="__check__"]': {
            padding: 0,
          },
          '& .MuiDataGrid-cell': {
            '&:focus': {
              border: 0,
              outline: 'none',
            },
            '&:focus-within': {
              border: 0,
              outline: 'none',
            },
          },
        },
      },
    },
    MuiGridMenu: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-gridMenuList': {
            boxShadow: theme.customShadows.z20,
            borderRadius: theme.shape.borderRadius,
          },
          '& .MuiMenuItem-root': {
            ...theme.typography.body2,
          },
        },
      },
    },
    MuiGridFilterForm: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1.5, 0),
          '& .MuiFormControl-root': {
            margin: theme.spacing(0, 0.5),
          },
          '& .MuiInput-root': {
            marginTop: theme.spacing(3),
            '&::before, &::after': {
              display: 'none',
            },
            '& .MuiNativeSelect-select, .MuiInput-input': {
              ...theme.typography.body2,
              padding: theme.spacing(0.75, 1),
              borderRadius: theme.shape.borderRadius,
              backgroundColor: theme.palette.background.neutral,
            },
            '& .MuiSvgIcon-root': {
              right: 4,
            },
          },
        },
      },
    },
    MuiGridPanelFooter: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
          // justifyContent: 'flex-end',
          '& .MuiButton-root': {
            '&:first-of-type': {
              marginRight: theme.spacing(1.5),
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
            '&:last-of-type': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            },
          },
        },
      },
    },
  };
}
