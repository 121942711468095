import React, { useEffect } from 'react';

// ChannelTalkの設定のスクリプト、このコンポーネントを配置するとChannelTalkが使えるようになる
export const ChannelIO: React.FC = () => {
  useEffect(() => {
    const w = window as any;

    if (w.ChannelIO) {
      console.error('ChannelIO script included twice.');
      return;
    }

    const ch: { (args?: any): void; q?: any[]; c?: (args: any) => void } = function (...args) {
      ch.c!(args);
    };

    ch.q = [];
    ch.c = function (args: any) {
      ch.q!.push(args);
    };

    w.ChannelIO = ch;

    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;

      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      const x = document.getElementsByTagName('script')[0];
      if (x.parentNode) {
        x.parentNode.insertBefore(s, x);
      }
    }

    if (document.readyState === 'complete') {
      l();
    } else {
      w.addEventListener('DOMContentLoaded', l);
      w.addEventListener('load', l);
    }

    w.ChannelIO('boot', {
      // TODO ここは環境変数で設定した方が良さそう
      pluginKey: 'bc57b745-4356-4268-97c2-aceee466adb2',
    });
  }, []);

  return null;
};
