import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';

interface ErrorContextType {
  showErrorDialog: boolean;
  setErrorDialog: (show: boolean) => void;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const ErrorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const setErrorDialog = useCallback((show: boolean) => {
    setShowErrorDialog(show);
  }, []);

  return (
    <ErrorContext.Provider value={{ showErrorDialog, setErrorDialog }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => {
  const context = useContext(ErrorContext);
  if (context === undefined) {
    throw new Error('useError must be used within an ErrorProvider');
  }
  return context;
};
