// i18n
import '../locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import cookie from 'cookie';
import { ReactElement, ReactNode } from 'react';
// next
import { NextPage } from 'next';
import Head from 'next/head';
import App, { AppProps, AppContext } from 'next/app';
//
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// @mui
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// redux
import { Amplify } from 'aws-amplify';
import { store, persistor } from '../redux/store';
// utils
import { getSettings } from '../utils/settings';
import { SettingsValueProps } from '../components/settings/type';
// contexts
import { SettingsProvider } from '../contexts/SettingsContext';
import { CollapseDrawerProvider } from '../contexts/CollapseDrawerContext';
// theme
import ThemeProvider from '../theme';
// components
import { ChartStyle } from '../components/chart';
import RtlLayout from '../components/RtlLayout';
import ProgressBar from '../components/ProgressBar';
import ThemeColorPresets from '../components/ThemeColorPresets';
import NotistackProvider from '../components/NotistackProvider';
import ThemeLocalization from '../components/ThemeLocalization';
import MotionLazyContainer from '../components/animate/MotionLazyContainer';

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version
import { AuthProvider } from '../contexts/AmplifyAuthContext';

// Amplify auth

import * as Sentry from '@sentry/react';

import { awsconfig } from 'src/config';

import { ApolloProvider } from '@apollo/client';
import { client } from 'src/utils/apolloClient';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ja from 'date-fns/locale/ja';
import { ErrorProvider, useError } from 'src/contexts/ErrorContext';
import ErrorDialog from 'src/components/dialog/ErrorDialog';
import { ChannelIO } from '../modules/ChannelTalk';

import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from 'msal-config';
import ProvideMSGraphContext from 'src/contexts/MSGraphContext';

Amplify.configure({
  ...awsconfig,
});

// ----------------------------------------------------------------------
type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface MyAppProps extends AppProps {
  settings: SettingsValueProps;
  Component: NextPageWithLayout;
}

export default function MyApp(props: MyAppProps) {
  const { Component, pageProps, settings } = props;
  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <Sentry.ErrorBoundary>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <AuthProvider>
        <ApolloProvider client={client}>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <CollapseDrawerProvider>
                <SettingsProvider defaultSettings={settings}>
                  <ThemeProvider>
                    <ErrorProvider>
                      <NotistackProvider>
                        <MsalProvider instance={msalInstance}>
                          <ProvideMSGraphContext>
                            <GoogleOAuthProvider clientId="522448693080-ctj88il9io2405tpo7nfj1stkstgql48.apps.googleusercontent.com">
                              <MotionLazyContainer>
                                <ThemeColorPresets>
                                  <ThemeLocalization>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                      dateFormats={{ normalDate: 'YYYY/mm/dd' }}
                                      locale={ja}
                                    >
                                      <ErrorHandler />
                                      <RtlLayout>
                                        <ChartStyle />
                                        {/* <Settings /> */}
                                        <ProgressBar />
                                        {getLayout(<Component {...pageProps} />)}
                                        <ChannelIO />
                                        {/* <Script
                                      id="ze-snippet"
                                      src="https://static.zdassets.com/ekr/snippet.js?key=f2d09aa9-2244-493d-ac15-da0d4fee7154"
                                    /> */}
                                      </RtlLayout>
                                    </LocalizationProvider>
                                  </ThemeLocalization>
                                </ThemeColorPresets>
                              </MotionLazyContainer>
                            </GoogleOAuthProvider>
                          </ProvideMSGraphContext>
                        </MsalProvider>
                      </NotistackProvider>
                    </ErrorProvider>
                  </ThemeProvider>
                </SettingsProvider>
              </CollapseDrawerProvider>
            </PersistGate>
          </ReduxProvider>
        </ApolloProvider>
      </AuthProvider>
    </Sentry.ErrorBoundary>
  );
}

// ----------------------------------------------------------------------

MyApp.getInitialProps = async (context: AppContext) => {
  const appProps = await App.getInitialProps(context);

  const cookies = cookie.parse(
    context.ctx.req ? context.ctx.req.headers.cookie || '' : document.cookie
  );

  const settings = getSettings(cookies);

  return {
    ...appProps,
    settings,
  };
};

const ErrorHandler: React.FC = () => {
  const { showErrorDialog, setErrorDialog } = useError();

  return <ErrorDialog open={showErrorDialog} onClose={() => setErrorDialog(false)} />;
};
