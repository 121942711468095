import { createSlice } from '@reduxjs/toolkit';
import { PageState } from 'src/@types/user';

// ----------------------------------------------------------------------

const initialState: PageState = {
  isOpenModal: false,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    openModal(state) {
      state.isOpenModal = true;
    },
    closeModal(state) {
      state.isOpenModal = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal } = slice.actions;
